import one from "Assets/video/transition-video.mp4";
import returnToWhoville from "Assets/video/transition-return-to-whoville.mp4";
import onToStageTwo from "Assets/video/transition-on-to-stage-two.mp4";
import alternateThumbnail from "Assets/video/thumbnail.webp";

export const ImageUrls = {
  // Commons
  logo: "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/How%20The%20Krinch%20Stole%20Christmas%20(Logo).png",
  cancel:
    "https://storage.googleapis.com/the-krinch-dev/Assets/Commons/cancel.png",
  infoIcon:
    "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/info.png",
  starFilled:
    "https://storage.googleapis.com/the-krinch-dev/Assets/Commons/star_filled.svg",
  starNotfilled:
    "https://storage.googleapis.com/the-krinch-dev/Assets/Commons/star_unfilled.svg",
  InformationPopup: {
    bulb: "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/chrismas_bulb.png",
  },

  // StageFirst
  StageFirst: {
    noPaper: {
      base: "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Base%20Image%20(No%20paper%20in%20printer%20tray).JPG",
      computerHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Highlight%20(Computer).JPG",
      doorLockHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Highlight%20(Door%20Padlock).JPG",
      noseLockHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Highlight%20(Nose%20Lock).JPG",
      soptTheDiffHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Highlight%20(Spot%20The%20Difference_Yellow).JPG",
      wardrobeHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Highlight%20(Wardrobe%20Lock).JPG",
      WordPuzzleHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Highlight%20(Word%20Highlighter%20Puzzle).JPG",
      redSpotTheDiffHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Red%20Highlight%20(Spot%20The%20Difference).JPG",
      redNoseLockHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_NoPaper/Nose_Red%20Highlight_Without_Paper.JPG",
    },
    withPaper: {
      base: "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Base%20Image%20(Paper%20in%20printer%20tray).JPG",
      computerHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Computer).JPG",
      doorLockHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Door%20Padlock).JPG",
      noseLockHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Nose%20Lock).JPG",
      redPrinterAndPaperHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Printer%20and%20Paper%20Red).JPG",
      printerHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Printer).JPG",
      soptTheDiffHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Spot%20The%20Difference_Yellow).JPG",
      wardrobeHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Wardrobe%20Lock).JPG",
      WordPuzzleHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Highlight%20(Word%20Highlighter%20Puzzle).JPG",
      redSpotTheDiffHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Red%20Highlight%20(Spot%20The%20Difference).JPG",
      redNoseLockHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Nose_Red%20Highlight_With_Paper.JPG",
      redWordPuzzleHiglighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/StageFirstBase_WithPaper/Red%20Highlight%20(Word%20Puzzle).JPG",
    },
    // Games
    CrazyNumLogic: {
      helpCircle:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Crazy-num-logic/help-circle.svg",
    },
    SearchWord: {
      background:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Search-word/background.png",
      clear:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Search-word/clear.svg",
      erase:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Search-word/erase.svg",
    },
    SpotTheDifference: {
      Spot_The_Difference_Base_Image:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/Spot_The_Difference_Base_Image.JPG",
      Spot_The_Difference_Version_A:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/Spot_The_Difference_Version_A.JPG",
      Spot_The_Difference_Version_B:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/Spot_The_Difference_Version_B.JPG",
      input_border:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/input_border.png",
      input_button:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Spot-the-diff/input_button.png",
    },
    WardrobeLock: {
      locked:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/Wardrobe_locked.JPG",
      unlocked:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/Wardrobe_unlocked.JPG",
    },
    DoorLock: {
      digits: {
        0: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/0.png",
        1: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/1.png",
        2: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/2.png",
        3: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/3.png",
        4: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/4.png",
        5: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/5.png",
        6: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/6.png",
        7: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/7.png",
        8: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/8.png",
        9: "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/Digits/9.png",
      },
      baseLock:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Lock%20GIF/BaseLock.JPG",
    },
    NoseLock: {
      stateOne:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/Elephant%20Nose%20(Version%201).jpg",
      stateTwo:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/Nose%20Lock%20(Unlocked).jpg",
    },
    spotTheDifferenceSolImage:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/Spot%20The%20Difference_Solution%20Yellow%20Arrow%20New.JPG",
    paperBg:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/paper-background.png",
    letter:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/The%20Letter%20from%20The%20Krinch%20(Compressed).jpg",
    WardrobeSolvePop:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/wardrobe_bubble.png",
    printerBubblePopUp:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageFirstBase/printer_bubble.png",
  },

  // StageTwo
  StageTwo: {
    stateOne: {
      base: "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Stage%202%20Base%20Image%20(Incomplete%20Puzzle).JPG",
      diaryHightlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Highlight%20(Diary).JPG",
      IncompletePuzzleHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Highlight%20(Incomplete%20Puzzle).JPG",
      notepadHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Highlight%20(Interviews).JPG",
      LandOrdinanceHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Highlight%20(Land%20Ordinance).JPG",
      LyingLandOwnersHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Highlight%20(Lying%20Landowners).JPG",
      redHighlightDiary:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%201%20(Incomplete%20Puzzle)/Red%20Highlight%20(Diary).JPG",
    },
    stateTwo: {
      base: "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Stage%202%20Base%20Image%20(Completed%20Puzzle).JPG",
      CompletePuzzleHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Highlight%20(Complete%20Puzzle).JPG",
      diaryHightlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Highlight%20(Diary).JPG",
      notepadHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Highlight%20(Interviews).JPG",
      LandOrdinanceHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Highlight%20(Land%20Ordinance).JPG",
      LyingLandOwnersHighlighted:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Highlight%20(Lying%20Landowners).JPG",
      redHighlightCompletPuzzle:
        "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/State%202%20(Completed%20Puzzle)/Red%20Highlight%20(Complete%20Puzzle).JPG",
    },
    LyingLandOwners:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/Lying%20Landowners%20Riddle.jpg",
    LandOrdinance:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/Lying%20Landowner%20(No%20background).PNG.png",
    Notepad:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/Notepad%20Interview%20(No%20background).PNG.png",
    Diary:
      "https://storage.googleapis.com/the-krinch-dev/Assets/StageTwo/The%20Krinch%E2%80%99s%20Diary.JPG",
    TreePuzzle: {
      reset:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/reset.svg",
      treeBackgroud:
        "https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/tree-backgroud.png",
      tree: "https://storage.googleapis.com/the-krinch-dev/Assets/Tree-puzzle/tree.png",
    },
  },

  // video thumbnails
  videoThumbnails: {
    one: "https://storage.googleapis.com/the-krinch-dev/Assets/videos/Thumbnail%20Krinch%20Transition%20Video.jpeg",
    "return-to-whoville": alternateThumbnail,
    "on-to-stage-two": alternateThumbnail,
    two: "https://storage.googleapis.com/the-krinch-dev/Assets/videos/Thumbnail%20Krinch%20Ending%20Video%20V3.png",
  },
};

export const VideoUrls = {
  one,
  "return-to-whoville": returnToWhoville,
  "on-to-stage-two": onToStageTwo,
  two: "https://storage.googleapis.com/the-krinch-dev/Assets/videos/Krinch%20Ending%20Video%20V3.mp4",
};
