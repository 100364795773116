export const videosNavigater = {
  one: {
    // onEndPath: "/mcrnd",
    onConfirm: "/matf/on-to-stage-two",
    onReject: "/matf/return-to-whoville",
  },
  "return-to-whoville": {
    onEndPath: "/rate-us",
  },
  "on-to-stage-two": {
    onEndPath: "/mcrnd",
  },
  two: {
    onEndPath: "/rate-us",
  },
};
