import VideoScreen from "../Views/VideoScreen";

const routes = [
  {
    path: "/matf/one",
    element: <VideoScreen videoNumber={"one"} />,
    title: "Video Screen One",
  },
  {
    path: "/matf/on-to-stage-two",
    element: <VideoScreen videoNumber={"on-to-stage-two"} />,
    title: "Video Screen One",
  },
  {
    path: "/matf/return-to-whoville",
    element: <VideoScreen videoNumber={"return-to-whoville"} />,
    title: "Video Screen One",
  },
  {
    path: "/matf/two",
    element: <VideoScreen videoNumber={"two"} />,
    title: "Video Screen Two",
  },
];

export default routes;
