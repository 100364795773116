import React, { useEffect, useMemo, useRef, useState } from "react";

import "./VideoScreen.scss";
import { ImageUrls, VideoUrls } from "Assets";
import commonThumbnail from "Assets/video/thumbnail.webp";
import { videosNavigater } from "./VideoNavigater";
import { useNavigate } from "react-router-dom";

import PlayIcon from "Assets/BuildAssets/play.svg";
import SkipIcon from "Assets/BuildAssets/skip.svg";
import MuteIcon from "Assets/BuildAssets/mute.svg";
import UnmuteIcon from "Assets/BuildAssets/unmute.svg";
import TransitionPopup from "Components/transitionPopup/TransitionPopup";

function VideoScreen({ videoNumber = "One" }) {
  const navigate = useNavigate();
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  // video controls
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted(!muted);
    }
  };

  const handleVideoEnd = () => {
    if (videosNavigater[videoNumber].onEndPath)
      return navigate(videosNavigater[videoNumber].onEndPath);

    videoRef.current?.pause();
    setPlaying(false);
    setOpenConfirmPopup(true);
    videoRef.current.poster = commonThumbnail;
  };

  const onVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.load();
    }
    handleVideoEnd();
  };

  const onReject = () => {
    videosNavigater[videoNumber].onReject &&
      navigate(videosNavigater[videoNumber].onReject);
    setOpenConfirmPopup(false);
  };

  const onConfirm = () => {
    videosNavigater[videoNumber].onConfirm &&
      navigate(videosNavigater[videoNumber].onConfirm);
    setOpenConfirmPopup(false);
  };

  const videoSrc = useMemo(() => VideoUrls[videoNumber], [videoNumber]);
  const videoThumbnailSrc = useMemo(
    () => ImageUrls.videoThumbnails[videoNumber],
    [videoNumber]
  );

  useEffect(() => {
    // Update the video source when the videoId changes
    if (videoRef.current) {
      videoRef.current.poster = videoThumbnailSrc;
      videoRef.current.src = videoSrc; // Set the new source
      videoRef.current.load(); // Load the new video
      setPlaying(false);
    }
  }, [videoSrc, videoThumbnailSrc]); // Dependency array includes videoSrc

  return (
    <>
      {/* Buttons */}
      <div
        style={{
          position: "absolute",
          bottom: "5%",
          left: "5%",
          zIndex: 10,
        }}
        onClick={toggleMute}
        className={`videoScreenButton ${
          muted ? "videoScreenButton_Yellow" : "videoScreenButton_Red"
        }`}
      >
        {muted ? (
          <>
            <span>UNMUTE</span>
            <img src={UnmuteIcon} alt="" />
          </>
        ) : (
          <>
            <span>MUTE</span>
            <img src={MuteIcon} alt="" />
          </>
        )}
      </div>

      {!playing && (
        <div
          style={{
            position: "absolute",
            bottom: "50%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10,
          }}
          onClick={togglePlay}
          className="videoScreenButton videoScreenButton_round"
        >
          <img className="player_icon" src={PlayIcon} alt="" />
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: "5%",
          right: "5%",
          zIndex: 10,
        }}
        onClick={handleVideoEnd}
        className="videoScreenButton videoScreenButton_Red"
      >
        <span>SKIP</span>
        <img src={SkipIcon} alt="" />
      </div>

      <div className="video_screen">
        <video
          ref={videoRef}
          poster={ImageUrls.videoThumbnails[videoNumber]}
          muted={muted}
          onEnded={onVideoEnd}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>

      {openConfirmPopup && (
        <TransitionPopup
          content={{
            secondaryText:
              "Do you want to continue to Stage 2 or head back to Whoville?",
            rejectBtnText: "Return To Whoville",
            confirmBtnText: "On To Stage 2",
          }}
          onReject={onReject}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
}

export default VideoScreen;
