import React from "react";

import "./TransitionPopup.scss";

const TransitionPopup = ({ content, onReject, onConfirm }) => (
  <div className="transition_popup_container">
    <div className="transition_popup">
      <div className="transition_popup_content">
        {/* <h1>{content.primaryText}</h1> */}
        <h2>{content.secondaryText}</h2>
        <div className="transition_popup_btns_wrap">
          <button
            className="transition_popup_content_button reject"
            onClick={onReject}
          >
            {content.rejectBtnText}
          </button>
          <button
            className="transition_popup_content_button"
            onClick={onConfirm}
          >
            {content.confirmBtnText}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default TransitionPopup;
